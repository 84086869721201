import * as JSON from './prediction_json';

export default {
  scenario: 'arctic_event',
  simulation: JSON.default,
  temperatureChange: '-30℉',
  lostGenerators: '106',
  naturalGas: '-12,126',
  solar: '-2,578',
  wind: '-1,421',
};
