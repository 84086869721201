import normal from './normal';
import prediction from './prediction';
import curtailment from './curtailment';
import generation from './generation';

export default [
  { type: 'Normal', data: normal },
  { type: 'Arctic Event', data: prediction },
  { type: 'Curtailment', data: curtailment },
  { type: 'Generation', data: generation },
];
