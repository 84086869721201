import { useEffect, useMemo, useState } from 'react';
import ToggleSwitch from '../ToggleSwitch';
import Legend from '../../assets/legend.png';
import SimTrendDown from '../../assets/simulation-trend-down.png';
import SimTrendSteady from '../../assets/simulation-trend-steady.png';
import SimTrendUp from '../../assets/simulation-trend-up.png';
import { ReactComponent as HistoryIcon } from '../../assets/icon-history.min.svg';
import styles from './card.module.scss';
import './card.global.scss';

/** cleanName - Remove "line_" prefix
 * @param {string} name
 * @returns {string}
 */
const cleanName = (name) => name.replace(/line_/i, '').replace('_', ' : ');

/** FauxTrend - Creates and <img> tag for Up/Steady/Down graphic & text
 * @param {Number} value
 * @returns {JSXElement}
 */
const FauxTrend = ({ value }) => {
  let src = SimTrendSteady;
  if (value > 50) src = SimTrendUp;
  if (value < -50) src = SimTrendDown;
  let text = `Steady at ${value}%`;
  if (value > 50) text = `Up ${value}%`;
  if (value < -50) text = `Down ${value}%`;
  return <img src={src} alt={text} title={text} />;
};

/** dataTimeCaseFilter - Keep relevant data */
const dataTimeCaseFilter = (scenarioCase, refTime, { case: dataCase, timestamp: dataTime }) =>
  dataTime >= refTime && dataTime <= refTime && dataCase === scenarioCase; // After AND Before?

/** Card - Component View */
export function Card({
  condition,
  data: {
    simulationTypesData,
    gridHealthData: { cases, lines, nodes },
    infoPanelData: { temperatureChange, lostGenerators, naturalGas, solar, wind },
    timestampsData: { maxTimestamp, minTimestamp, steps, timestamps },
  },
  handleCaseChange,
  handleSetCondition,
  handleSliderChange,
  handleToggleTimeline,
  scenarioCase,
  switchToggled,
  timestamp,
  timestep,
}) {
  const timezoneAdjustedDate =
    timestamp &&
    new Date(timestamp * 1000).toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
  const timestring = timestamp
    ? `${new Date(timezoneAdjustedDate).toLocaleDateString()} ${new Date(
        timezoneAdjustedDate,
      ).toLocaleTimeString()}`
    : '';

  // NOTE: we should only iterate once on data but yolo
  const filteredLines = lines.filter(dataTimeCaseFilter.bind(null, scenarioCase, timestamp));

  // NOTE: in the future this is a good candidate for a
  // dedicated API route for the filtered data
  const filteredNodes = nodes.filter(dataTimeCaseFilter.bind(null, scenarioCase, timestamp));

  return (
    <>
      {!timestring ? null : (
        <header className={styles.dateTime}>
          <span>{timestring}</span>
        </header>
      )}
      <aside className={styles.mapPlotControl} alt="Map Plot Control">
        <div className={styles.inner}>
          {simulationTypesData.map((c) => (
            <button
              key={Math.random() * 15e15}
              className={c === condition ? 'active' : null}
              type="button"
              onClick={handleSetCondition.bind(null, c)}
              title={`Condition:\n${c}`}
            >
              {c}
            </button>
          ))}
        </div>
        <div className={styles.additionalControls}>
          <div className={styles.scenarioCase} disabled={cases.length < 2}>
            {scenarioCase}
            <ToggleSwitch
              checked={switchToggled}
              onChange={(checked) => handleCaseChange(checked)}
            />
          </div>
          <button
            className={styles.iconHistory}
            type="button"
            onClick={handleToggleTimeline}
            title="Timeline"
          >
            <HistoryIcon />
          </button>
          <input
            className={styles.SimulationTestTimelineSlider}
            disabled={!timestamps.length}
            type="range"
            value={timestep}
            onChange={handleSliderChange}
            min="0"
            max={steps - 1} // Minus one, because we are starting at the first step
            step="1"
          />
        </div>
      </aside>

      <img src={Legend} className={styles.plotLegend} alt="Legend" title="Legend" />

      <aside className={styles.infoPanel} alt="Info Panel">
        <h3 className={styles.CardListTitle}>Event details</h3>
        <div className={styles.CardDivider} />
        <div className={styles.temperatureChange}>
          <h4 className={styles.CardListItemLabel}>TEMPERATURE CHANGE:</h4>
          <span className={styles.CardListItemValue}>{temperatureChange}</span>
        </div>
        <div className={styles.CardDivider} />
        <div className={styles.lostGenerators}>
          <h4 className={styles.CardListItemLabel}>LOST GENERATORS:</h4>
          <span className={styles.CardListItemValue}>
            {lostGenerators}
            <span>UNITS</span>
          </span>
        </div>
        <div className={styles.CardDivider} />
        <div className={styles.naturalGas}>
          <h4 className={styles.CardListItemLabel}>NATURAL GAS:</h4>
          <span className={styles.CardListItemValue}>
            {naturalGas}
            <span>㎿</span>
          </span>
        </div>
        <div className={styles.CardDivider} />
        <div className={styles.solar}>
          <h4 className={styles.CardListItemLabel}>SOLAR:</h4>
          <span className={styles.CardListItemValue}>
            {solar}
            <span>㎿</span>
          </span>
        </div>
        <div className={styles.CardDivider} />
        <div className={styles.wind}>
          <h4 className={styles.CardListItemLabel}>WIND:</h4>
          <span className={styles.CardListItemValue}>
            {wind}
            <span>㎿</span>
          </span>
        </div>

        <div className={styles.CardDivider} />

        <div className={styles.Card}>
          <h3 className={styles.CardListTitle}>Nodes</h3>
          <div className={styles.CardList}>
            {filteredNodes.map(({ name, timestamp, p_mw: value }) => (
              <div className={styles.CardListItem} key={`${name}-${timestamp}}`}>
                <div>
                  <h4 className={styles.CardListItemLabel}>{cleanName(name)}</h4>
                  <div className={styles.CardListItemValue}>
                    {value}
                    <span>㎿</span>
                  </div>
                </div>
                <FauxTrend value={value} />
              </div>
            ))}
          </div>

          <div className={styles.CardDivider} />

          <h3 className={styles.CardListTitle}>Lines</h3>
          <div className={styles.CardList}>
            {filteredLines.map(({ name, timestamp, loading_percent: value }) => (
              <div className={styles.CardListItem} key={`${name}-${timestamp}`}>
                <div>
                  <h4 className={styles.CardListItemLabel}>{cleanName(name)}</h4>
                  <div className={styles.CardListItemValue}>{value}%</div>
                </div>
                <FauxTrend value={value} />
              </div>
            ))}
          </div>
        </div>
      </aside>
    </>
  );
}
