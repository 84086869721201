import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Elasticsearch,
  Facet,
  Results,
  SearchBox,
  fromUrlQueryString,
  toUrlQueryString,
} from 'react-elasticsearch';
// import useAutocomplete from 'use-autocomplete';
import localAzFnURI from '../../utils/localAzFnUri';
import replaceJSX from '../../utils/replaceJSX';
import randInt from '../../utils/randInt';
import getConfig from '../../config';
import { ReactComponent as MagnifyIcon } from '../../assets/icon-ui-magnify.min.svg';
import { ReactComponent as UIShowIcon } from '../../assets/icon-ui-show.min.svg';
import { ReactComponent as UIDownloadIcon } from '../../assets/icon-ui-download.min.svg';
import mapping from './mapping';
import './index.global.scss';

const { ELASTIC_URI } = getConfig();
const elasticURI = localAzFnURI(ELASTIC_URI);

const itemsPer = 10;

// /** caseInsensitiveSearch
//  * Return results regardless of string-casing
//  * @param {string} text
//  * @returns
//  */
// const caseInsensitiveSearch = (text) =>
//   text.replace(/([a-z])/gi, (w, x) => `[${x.toUpperCase()}${x.toLowerCase()}]`);

const customQuery = (query) => {
  if (!query) {
    return { match_all: {} };
  }
  return {
    bool: {
      must: [
        {
          bool: {
            must: [],
            filter: [
              {
                multi_match: {
                  type: 'best_fields',
                  query /*: `filename,${query}`*/,
                  lenient: true,
                },
              },
            ],
            should: [],
            must_not: [],
          },
        },
      ],
    },
  };
};

/** HighlightSpan
 * Wraps text in a <span> tag
 * @param {string} content - all text to search
 * @param {RegExp} regex
 * @param {string} text case-sensitive/respecting value from original content (when `i` flag used)
 */
const HighlightSpan = (content, regex, text) =>
  replaceJSX(content, regex, <span key={randInt()}>{text}</span>);

/** displayItems
 * Maps result data to JSX/layout-format
 * @param {Object} data
 * @returns {JSXElement}
 */
const displayItems = (data, queryText) => (
  <div className="modal">
    <dl>
      {data.map(({ _source, _id: id }) => {
        const filepath = mapping[id]?.filepath;
        const filename = filepath?.replace('/PDF/', '');
        // Find case-insensitive matches (non-whole words, hence anywhere in content)
        const matchQuery = new RegExp(queryText, 'gi');
        // Get the exact matched value, respecting character-case, for highlighted string(s)
        const content = HighlightSpan(
          _source.message,
          matchQuery,
          (_source.message.match(matchQuery) || []).shift() ?? '',
        );

        return (
          <Fragment key={randInt()}>
            <dt title="View Search Result">
              <input className="modal__trigger" id={`modal__trigger_${id}`} type="checkbox" />
              <label htmlFor={`modal__trigger_${id}`}>
                {filename ?? id}
                <UIShowIcon className="icon-ui-show" />
              </label>
              {filepath && (
                <a
                  href={filepath}
                  title="Download Search Results Document"
                  target="_blank"
                  rel="noreferrer"
                >
                  <UIDownloadIcon className="icon-ui-download" />
                </a>
              )}
              <div
                className="modal__overlay"
                role="dialog"
                aria-labelledby="modal__title"
                aria-describedby="modal_desc"
              >
                <div className="modal__wrap">
                  <label htmlFor={`modal__trigger_${id}`}>&#10006;</label>
                  <h2 id="modal__title">
                    DOCUMENT : : {filename ?? id}
                    {filepath && (
                      <a
                        href={filepath}
                        title="Download Search Results Document"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UIDownloadIcon className="icon-ui-download" />
                      </a>
                    )}
                  </h2>
                  <p id="modal__desc">{content}</p>
                </div>
              </div>
            </dt>
            <dd>{content}</dd>
          </Fragment>
        );
      })}
    </dl>
  </div>
);

/** getQueryText
 * Retrieves and decodes URI query value
 * @returns {string}
 */
const getQueryValues = () => {
  const hash = window.location.hash;
  const uriQuery = hash.includes('/Search') ? hash.split('=')?.pop() ?? '' : '';
  const queryText =
    (uriQuery && decodeURIComponent(uriQuery.replace(/^%22(.*)%22$/, '$1'))).trim() || '';
  const initialValues = fromUrlQueryString(queryText);
  return { queryText, initialValues };
};

/** SortableResults - Search page results */
export function SortableResults() {
  const { queryText, initialValues } = getQueryValues();
  const [queryString, setQueryString] = useState(queryText);
  const [sortKey, setSortKey] = useState('creationDate.keyword');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortQuery, setSortQuery] = useState([{ [sortKey]: { order: sortOrder } }]);

  useEffect(() => {
    setSortQuery([{ [sortKey]: { order: sortOrder } }]);
  }, [sortKey, sortOrder]);

  return (
    <Elasticsearch
      url={elasticURI}
      onChange={() => setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 97)}
    >
      <SearchBox customQuery={customQuery} id="for" placeholder="Search" initialValue={queryText} />
      <Results
        id="result"
        // sort={sortQuery}
        itemsPerPage={itemsPer}
        initialPage={initialValues.get('resultPage')}
        items={(data) => displayItems(data, queryText)}
        // eslint-disable-next-line react/no-unstable-nested-components
        stats={(total) => (
          <>
            {/* Sort by:{' '}
            <select onChange={(e) => setSortKey(e.target.value)} value={sortKey}>
              {['creationDate.keyword'].map((e) => (
                <option key={e} value={e}>
                  {e.replace('.keyword', '')}
                </option>
              ))}
            </select>
            <select onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
              <option value="asc">asc</option>
              <option value="desc">desc</option>
            </select> */}
            <div className="react-es-stats">
              {total} results for <em>{queryText}</em>
            </div>
          </>
        )}
      />
    </Elasticsearch>
  );
}

/** NavSearchbar - header search field */
export default function NavSearchbar() {
  const { queryText, initialValues } = getQueryValues();
  const [queryString, setQueryString] = useState(queryText);
  // const [textState, setTextState] = useState('');
  // const [completions] = useAutocomplete(textState);
  const navigate = useNavigate();
  let prevSearchText = queryText || '';

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const input = form.querySelector('input');
    const searchText = input?.value.trim() ?? '';
    // Blank
    if (!searchText) input.focus();
    // No query change
    else if (searchText === prevSearchText) input.blur();
    // Navigate
    else {
      navigate(`/Search?${queryString}`);
      prevSearchText = searchText;
    }
  };

  // useEffect(() => {
  //   const navSearchInput = document.querySelector('.react-es-facet > input');
  //   console.log('navSearchInput', navSearchInput);
  //   navSearchInput.addEventListener('change', (event) => {
  //     const result = document.querySelector('.result');
  //     result.textContent = `You like ${event.target.value}`;
  //   });
  // }, []);

  return (
    <form id="nav_search_form" onSubmit={onSubmit}>
      <Elasticsearch
        url={elasticURI}
        onChange={(values) => {
          setQueryString(toUrlQueryString(values));
        }}
      >
        <SearchBox
          customQuery={customQuery}
          id="for"
          fields={['message.keyword']}
          placeholder="Search"
          initialValue={queryString}
          // initialValue={initialValues.get('message')}
        />
        {/*<Results id="result" items={data => data.map(item => <>{item._source.title}</>)} />*/}
        {/* <Results id="result" items={data => data.map(item => <>{item}</>)} /> */}
        {/* <Results
        id="result"
        initialPage={initialValues.get("resultPage")}
        items={data =>
          data.map(({ _source, _id }) => <div key={_id}>{_source.TICO}</div>)
        }
      /> */}
      </Elasticsearch>
      <button type="submit">
        <MagnifyIcon className="search-icon" />
      </button>
    </form>
  );
}
