import Main from '../../layout/Main';
import classNames from '../../utils/classNames';
import { ReactComponent as DashboardInnerFuelMix } from '../../assets/realtime-alert-1.1-inner-fuelmix.min.svg';
import { ReactComponent as DashboardInnerTotalCapacity } from '../../assets/realtime-alert-1.1-inner-totalcapacity.min.svg';
import { ReactComponent as AlertInnerSimilarWeatherEvents } from '../../assets/realtime-alertexpanded-1.2-inner-similarweatherevents.min.svg';
import { ReactComponent as AlertInnerTemperatureChange } from '../../assets/realtime-alertexpanded-1.2-inner-temperaturechange.min.svg';
import styles from './index.module.scss';

export default function Dashboard() {
  return (
    <Main className={styles.Dashboard} title="Static">
      <div
        className={classNames(styles.centerContent, styles.dashboard)}
        style={{ backgroundImage: "url('/realtime-alert-1.1-full.min.svg')" }}
      >
        <div className={styles.responsive}>
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 801 839"
            width="801"
            height="839"
          >
            <DashboardInnerFuelMix title="Fuel Mix" />
          </svg>
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1085 839"
            width="1085"
            height="839"
          >
            <DashboardInnerTotalCapacity title="Total Capacity" />
          </svg>
        </div>

        <div
          className={classNames(styles.centerContent, styles.alert)}
          style={{ backgroundImage: "url('/realtime-alertexpanded-1.2-full.min.svg')" }}
        >
          <div className={styles.responsive}>
            <svg
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 801 839"
              width="801"
              height="839"
            >
              <AlertInnerTemperatureChange title="Temperature Change" />
            </svg>
            <svg
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1085 839"
              width="1085"
              height="839"
            >
              <AlertInnerSimilarWeatherEvents title="Similar Weather Events" />
            </svg>
          </div>
        </div>
      </div>
    </Main>
  );
}
