import * as JSON from './generation_json';

export default {
  scenario: 'generation',
  simulation: JSON.default,
  temperatureChange: '-30℉',
  lostGenerators: '21',
  naturalGas: '-2,425',
  solar: '-516',
  wind: '-284',
};
