import { /*Redirect,*/ HashRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Search from '../pages/Search';
import Simulation from '../pages/Simulation';
// import getConfig from '../config';

// const { DEPLOYMENT_BASENAME } = getConfig(); // Not needed for HashRouter

/** Pass query-parameters to page/redirection
 * @example Use this pattern to preserve and pass URI data forward
 *  <Route
 *    path="/PageName"
 *    render={({ location }) => <Redirect to={{ ...location, pathname: '/SameOrDifferentPage' }} />}
 *  />
 */

export default function AppRouter({ injectedHooks }) {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route path="/Dashboard" element={<Dashboard />} />

        <Route path="/Simulation" element={<Simulation />} />

        <Route path="/Search" element={<Search />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
