import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

/** withRouter - Wrapper
 * @see https://reactrouter.com/docs/en/v6/faq#what-happened-to-withrouter-i-need-it
 * @param {Component}
 * @returns {Component}
 */
export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}
