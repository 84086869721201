// import { useEffect } from 'react';
import Main from '../../layout/Main';
import styles from './index.module.scss';

/* NOTE: Footer Component renamed and repurposed as Timeline */
// /** footerExample - Simple footer show/hide demo */
// const footerExample = () => {
//   const toggleFooterBtn = document.querySelector('.toggle-footer');
//   const footer = document.querySelector('footer');
//
//   function toggleFooter(e) {
//     e.stopPropagation();
//     if (footer.classList.contains('hidden')) {
//       footer.classList.remove('hidden');
//     } else {
//       footer.classList.add('hidden');
//     }
//   }
//
//   toggleFooterBtn.addEventListener('click', toggleFooter, false);
// };

export default function Home() {
  // useEffect(() => footerExample(), []);

  return (
    <Main className={styles.Home}>
      Home
      {/* <br />
      <button className={styles.toggleFooter} type="button">
        toggle footer
      </button> */}
    </Main>
  );
}
