import { useQuery } from 'react-query';
import SimulationAPI from '../../apis/SimulationAPI';

// UTILS:

/** getWaypoints - Convert point data into waypoints for TripsLayer
 * @param {Object} record Input data
 * @param {Number} startTimestamp
 * @param {Number} endTimestamp
 * @returns {Object} Expanded record data
 */
const getWaypoints = (record /*, startTimestamp, endTimestamp*/) => ({
  waypoints: [
    { coordinates: record.from_lat_long, timestamp: 1613174400 }, // Start
    { coordinates: record.to_lat_long, timestamp: 1613001600 }, // End
  ],
  ...record,
});

/** transformGridHealthData - Separates lines and nodes
 * @param {Object} data
 * @returns {Object}
 */
const transformGridHealthData = ({
  simulation: { result, sim_options: { time_step: timeStep } = {} },
}) =>
  result.reduce(
    (collection, record) => {
      // Filter slack bus/line
      if ([record.bus_type, record.line_type].includes('slack')) return collection;

      // Separate buses and lines + get unique case values
      if (record.object === 'line') {
        record = getWaypoints(record);
        collection.lines.push(record);
      } else collection.nodes.push(record);
      // Unique cases
      collection.cases = [...new Set([...collection.cases, record.case])];
      return collection;
    },
    { cases: [], lines: [], nodes: [] },
  );

/** transformInfoPanelData - Isolates Event details data
 * @param {Object} data
 * @returns {Object}
 */
function transformInfoPanelData({ temperatureChange, lostGenerators, naturalGas, solar, wind }) {
  return { temperatureChange, lostGenerators, naturalGas, solar, wind };
}

/** transformSimulationOptionsData - Updates viewState latitude and zoom based on device viewport
 * @returns {Object}
 */
const transformSimulationOptionsData = ({ longitude, latitude, zoom, pitch, bearing }) => {
  const innerHeight = window.innerHeight / window.devicePixelRatio;
  const gtH960 = window.innerHeight > 960;
  const aspectRatio = window.outerHeight / window.outerWidth;
  return {
    bearing,
    pitch,
    longitude,
    latitude: latitude - (!gtH960 && aspectRatio > 1.7 ? 2 : 0),
    zoom: (innerHeight * 0.99) ** 0.21 + (gtH960 ? 0 : 0.3),
  };
};

/** transformTimestampsData - Calculates Timestamps data
 * @param {Object} data
 * @returns {Object}
 */
const transformTimestampsData = ({
  simulation: { result, sim_options: { time_step: timeStep } = {} },
}) => {
  const timestamps = [...new Set(result.map((record) => record.timestamp))];
  const steps = timestamps.length;
  const minTimestamp = timestamps[0];
  const maxTimestamp = timestamps[steps - 1];

  return { steps, minTimestamp, maxTimestamp, timestamps, timeStep };
};

/** getData - Fetch simulation data from API */
const getData = ({ queryKey }) => {
  const [_, condition] = queryKey;
  return SimulationAPI.getSimulation(condition);
};

// REACT-QUERY DATA CACHING:

/** useGridHealthQuery - Caches fetched [Simulate] API data
 * @param {?string} condition
 * @param {Object} options
 */
const useGridHealthQuery = (condition, options) =>
  useQuery(['grid-health', condition], getData, {
    initialData: {
      temperatureChange: 0,
      lostGenerators: 0,
      naturalGas: 0,
      solar: 0,
      wind: 0,
      simulation: { result: [] },
    },
    ...options,
  });

/** useSimulationOptionsQuery - Caches fetched [Simulate Options] API data */
const useSimulationOptionsQuery = () =>
  useQuery('simulation-options', SimulationAPI.getMapConfig, {
    select: transformSimulationOptionsData,
    initialData: {
      // Defaults:
      longitude: -90,
      latitude: 40.5,
      zoom: 4.8,
    },
  });

/** useSimulationTypesQuery - Caches fetched [Simulate Types] API data */
const useSimulationTypesQuery = () =>
  useQuery('simulation-types', SimulationAPI.getTypes, {
    initialData: [],
  });

// EXPORT:

/** useGridHealthData - Returns specialized and transformed data which was fetched from the API
 * @param {?string} condition
 * @returns {Object}
 */
export function useGridHealthData(condition) {
  return {
    simulationOptionsData: useSimulationOptionsQuery(),
    simulationTypesData: useSimulationTypesQuery(),
    gridHealthData: useGridHealthQuery(condition, { select: transformGridHealthData }),
    infoPanelData: useGridHealthQuery(condition, { select: transformInfoPanelData }),
    timestampsData: useGridHealthQuery(condition, { select: transformTimestampsData }),
  };
}
