import * as JSON from './normal_json';

export default {
  scenario: 'normal',
  simulation: JSON.default,
  temperatureChange: '0℉',
  lostGenerators: '0',
  naturalGas: '526',
  solar: '64',
  wind: '40',
};
