import { useState, useCallback } from 'react';
import { DeckGL } from '@deck.gl/react';
import { MapView } from '@deck.gl/core';
import { Map } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ReactComponent as HistoryIcon } from '../../assets/icon-history.min.svg';
import SimulationAPI from '../../apis/SimulationAPI';
import getConfig from '../../config';
import styles from './index.global.scss';

const { MAPBOX_TOKEN } = getConfig();

// Test for Jest
if (typeof jest === 'undefined')
  // eslint-disable-next-line global-require, import/no-webpack-loader-syntax, import/no-unresolved
  mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAP_VIEW = new MapView({
  controller: true,
});

const glOptions = {
  powerPreference: 'high-performance',
};

function getCursor({ isHovering }) {
  return isHovering ? 'pointer' : 'crosshair';
}

const navigationControlStyles = {
  top: 16,
  right: 16,
};

export function ReactMap(props) {
  const { children, ...rest } = props;
  const [cursor, setCursor] = useState('crosshair');

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('crosshair'), []);

  return (
    <DeckGL
      views={MAP_VIEW}
      useDevicePixels={false}
      glOptions={glOptions}
      cursor={cursor}
      {...rest}
    >
      {children}
      <Map
        // reuseMaps
        mapStyle="https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json"
        mapboxAccessToken={MAPBOX_TOKEN}
      />
    </DeckGL>
  );
}
