import { ReactComponent as TimelineTile } from '../../assets/TimelineTile.svg';
import styles from './index.module.scss';

/** Footer - Page Component */
export default function Timeline() {
  return (
    <footer className={styles.Timeline} alt="Timeline">
      <TimelineTile className={styles.TimelineTile} />
    </footer>
  );
}
