import * as JSON from './curtailment_json';

export default {
  scenario: 'curtailment',
  simulation: JSON.default,
  temperatureChange: '-30℉',
  lostGenerators: '42',
  naturalGas: '-4,850',
  solar: '-1,031',
  wind: '-568',
};
