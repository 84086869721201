import { useEffect, useState } from 'react';
import Main from '../../layout/Main';
import { SortableResults } from '../../components/Elasticsearch';
import withRouter from '../../utils/withRouter';
import './index.scss';

/** Search - Render search results with sorting and pagination controls */
export default withRouter(() => {
  const [loaded, setLoaded] = useState(1);
  const [queried, setQueried] = useState('');
  const queryText = window.location.hash.split('=')?.pop() ?? '';

  const Content = () => (queryText ? <SortableResults /> : null);

  return (
    <Main className="Search" title="Search Results">
      <Content />
    </Main>
  );
});
