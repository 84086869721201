// import localAzFnURI from '../../utils/localAzFnUri';
// import getConfig from '../config';
//
// const { SIMULATION_URI } = getConfig();
//
// /** Define URIs used in request methods */
// const uri = { simulation: localAzFnUri(SIMULATION_URI) };
// uri.types = `${uri.simulation}/types`;
// uri.options = `${uri.simulation}/mapConfig`;

// Fixture/static data - normally called from [microservice] APIs (e.g. code above)
import simulations from './__fixtures__/simulations';
import types from './__fixtures__/types';
import mapConfig from './__fixtures__/mapConfig';

/** SimulationAPI - Methods to request API data */
export default {
  async getSimulation(condition) {
    /*
      const data = [undefined, 'Normal'].includes(condition)
        ? await fetch(uri.simulation) // GET (default, for "normal" condition)
        : await fetch(uri.simulation, {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ condition }),
          });
      const simulation = await data.json();
      return simulation;
    */
    // Mock API request to simulate the fetch example above
    const mockRequest = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(simulations.find((s) => s.type === condition));
      }, Math.random() * 100);
    });
    return mockRequest.data;
  },
  async getTypes() {
    /*
      const data = await fetch(uri.types);
      const types = await data.json();
      return types;
    */
    // Mock API request to simulate the fetch example above
    const mockRequest = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(types);
      }, Math.random() * 100);
    });
    return mockRequest;
  },
  async getMapConfig() {
    /*
      const data = await fetch(uri.mapConfig);
      const mapConfig = await data.json();
      return mapConfig;
    */
    // Mock API request to simulate the fetch example above
    const mockRequest = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(mapConfig);
      }, Math.random() * 100);
    });
    return mockRequest;
  },
};
